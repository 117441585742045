import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'settings', pathMatch: 'full' },

  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/home/home.routes').then((r) => r.HOME_ROUTES),
  },

  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.route').then((r) => r.SETTINGS_ROUTES),
  },

  {
    path: 'intranet',
    loadComponent: () =>
      import('./modules/intranet/pages/intranet-page/intranet-page.component').then(
        (c) => c.IntranetPageComponent
      ),
  },

  { path: '**', redirectTo: 'settings', pathMatch: 'full' },
];
