import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
  Inject
} from '@angular/core';
import {
  PeakAvatarModule,
  PeakButtonModule,
  PeakDialogService,
  PeakHeaderModule,
} from 'peak-ui';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Menu } from '@core-models/menu.model';
import { PeakShellModule } from 'peak-shell';
import { UserRole } from '@core-models/user-role.enum';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslatePipe } from '@core-pipes/translate.pipe';
import { EditLocationComponent } from './modules/home/pages/components/edit-location/edit-location.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LoadingComponent } from '@shared-components/loading/loading.component';
import { CalculateUserRoleDirective } from '@shared-directives/calculate-roles.directive';
import { CurrentUser } from '@shared-models/current-user.model';
import LanguageService from '@shared-services/language.service';
import { MeService } from '@shared-services/me.service';
import { CustomToastrService } from '@shared-services/custom-toastr.service';
import { RegionalService } from '@shared-services/regional.service';
import { TranslateService } from '@shared-services/translate.service';
import { environment } from '@environments/environment';
import { AvailableLanguage } from '@shared-models/available-languages.model';
import { Title } from '@angular/platform-browser';
import { Observable, combineLatest, filter, map, mergeMap } from 'rxjs';
import { ScreenSizeService } from '@shared-services/screen-size.service';
import { LogoutAction, UserAuth } from '@core-models/user-model';
import { ScrollService } from '@shared-services/scroll.service';
@Component({
  selector: 'vlc-root',
  standalone: true,
  imports: [
    RouterOutlet,
    PeakHeaderModule,
    CommonModule,
    PeakAvatarModule,
    PeakButtonModule,
    PeakShellModule,
    CalculateUserRoleDirective,
    TranslatePipe,
    ConfirmDialogModule,
    LoadingComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private meService = inject(MeService);
  private destroyRef = inject(DestroyRef);
  private screenSizeService = inject(ScreenSizeService);
  private scrollService = inject(ScrollService);
  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;
  profileImageUri: string = '../assets/img/empty-profile-picture.png';
  leftOverlayButtonIcon = 'pi pi-question';
  middleOverlayButtonIcon = 'pi pi-th-large';
  rightOverlayButtonIcon = 'pi pi-bell';
  menuItems = [
    {
      label: 'vlc:Admin:Homepage',
      icon: '',

      items: [
        {
          label: 'Widget List',
          icon: 'pi pi-list',
          routerLink: ['/admin/widget-list'],
        },
      ],
    },
    {
      label: 'vlc:Admin:Settings',
      icon: '',
      items: [
        {
          label: 'vlc:Admin:Company',
          icon: 'pi pi-building',
          routerLink: ['/settings'],
        },
        {
          label: 'vlc:Admin:Notifications',
          icon: 'pi pi-bell',
          routerLink: ['settings/notification'],
        },
        {
          label: 'vlc:Admin:Integrations',
          icon: 'pi pi-link',
          routerLink: ['/settings'],
        },
        {
          label: 'vlc:Admin:Manage',
          icon: 'pi pi-users',
          routerLink: ['settings/management'],
        },
        {
          label: 'vlc:Admin:Themes',
          icon: 'pi pi-palette',
          routerLink: ['/settings'],
        },
      ],
    },
    {
      label: 'vlc:Admin:Intranet',
      icon: '',
      routerLink: ['/settings'],
    },
  ];

  avatarMenu: Menu[] = [
    {
      label: 'vlc:Admin:Company.Settings.Language.Label',
      icon: 'pi pi-angle-left',
      items: [],
    },
  ];
  selectedLanguage: string = '';
  translatedMenu: Menu[] = [];
  profile: CurrentUser = new CurrentUser();
  oncommand: string = '';
  logout: LogoutAction[] = [];
  userRole: string = '';
  isMasterAdmin: boolean = false;
  translatedAvatarMenu: Menu[] = [];
  permissionScope: UserRole = UserRole.User;
  endUser: boolean = false;
  menuType: 'tiered' | 'slide' = 'tiered';
  isSmallOrMediumScreen$: Observable<boolean>;
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private customToastrService: CustomToastrService,
    private peakDialogService: PeakDialogService,
    private regionalService: RegionalService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.getUserInfo();
    this.isSmallOrMediumScreen$ =  combineLatest([
      this.screenSizeService.isScreenSize$('small'),
      this.screenSizeService.isScreenSize$('medium')
    ])
    .pipe(
      map(([isSmall, isMedium]) => isSmall || isMedium)
    )
  }

  ngOnInit(): void {
    this.setAvailableLanguages();
    this.languageService.onLanguageChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((language) => {
        this.selectedLanguage = language;
        this.document.documentElement.lang = language;
        this.translatedMenu = this.translateMenu(this.menuItems);
        this.translatedAvatarMenu = this.translateMenu(this.avatarMenu);
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data)
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (data['title']) {
          const translatedTitle = this.translateService.translateInstant(
            data['title']
          );
          this.titleService.setTitle(`Velocity - ${translatedTitle}`);
        }
      });
  }

  setAvailableLanguages() {
    const sortedLanguages = environment.availableLangs.sort(
      (a, b) => a.Order - b.Order
    );
    this.avatarMenu[0].items = sortedLanguages.map(
      (language: AvailableLanguage) => {
        return {
          label: language.Label,
          icon: `custom-icon-${language.IsoCode}`,
          command: async () => {
            await this.setLanguage(language.IsoCode);
            this.customToastrService.success(
              this.translateService.translateInstant(language.Notify)
            );
          },
        };
      }
    );
  }

  translateMenu(menuItems: Menu[]): Menu[] {
    const translateItem = (item: Menu): Menu => {
      const translatedItem = { ...item };
      translatedItem.label = this.translateService.translateInstant(item.label);
      if (translatedItem.items) {
        translatedItem.items = translatedItem.items.map((subItem) =>
          translateItem(subItem)
        );
      }
      return translatedItem;
    };

    return menuItems.map(translateItem);
  }

  async setLanguage(language: string) {
    this.selectedLanguage = language;
    await this.translateService.setCurrentLanguage(language);
    await this.languageService.setCurrentLanguage(language);
    this.languageService.saveLanguageToLocalStorage(language);
    this.regionalService.registerCulture(language);
    this.document.documentElement.lang = language;
  }

  getResolvedProfile(userInformations: UserAuth) {
    if (userInformations) {
      this.isMasterAdmin = userInformations.isMasterAdmin;
    }
  }

  getUserInfo() {
    this.meService.currentUser$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.profile = user;
        this.permissionScope = user.PermissionScope;
      });
  }
  getLogoutAction(logout: LogoutAction[]) {
    this.logout = logout;
    this.oncommand = logout[0].text;
  }

  editLocation() {
    this.peakDialogService.open(EditLocationComponent, {
      header: this.translateService.translateInstant(
        'vlc:Admin:Company.Settings.Language.Label'
      ),
      height: '230px',
      dismissableMask: false,
      showHeader: true,
      closeOnEscape: false,
      maximizable: false,
    });
  }

  doLogout() {
    if (this.logout[1].type == 'Master') {
      this.logout[1].command();
    } else if (this.logout[0].type == 'Normal') {
      this.logout[0].command();
    }
  }
  scrollToTop(): void {
    this.scrollService.scrollToTopWithAnimation(600);
  }
}
